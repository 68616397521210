.not-found {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: slide-up 1s;
}

@keyframes slide-up {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

}

.home-page {
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

button {
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
}

.signup {
  background-color: #f7931a;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.signup:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.signin {
  background-color: #fff;
  color: #f7931a;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.signin:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}


.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: #f7f7f7;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  background-color: #2a69ac;
  color: #fff;
  cursor: pointer;
}

.error {
  display: block;
  margin-top: 5px;
  color: red;
  font-size: 0.9rem;
}
  
@media (max-width: 768px) {
  form {
    margin-top: 20px;
  }
  
  input[type="email"],
  input[type="password"],
  button[type="submit"] {
    font-size: 1rem;
    padding: 8px;
  }
}


.header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 1vmin);
  color: white;
  z-index: 999999;
  padding: 0.5%;
  position: sticky;
  width: 100%;
  z-index: 1000;
  align-items: inherit;



}
header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.logo-container {
  display: flex;
  align-items: right !important;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-text {
  align-items: right !important;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: #f3ba2f;;
  border-radius: 10px;
  padding: 3px 25px 3px 25px;
  margin: 8px 10px 8px 10px;
  text-shadow: 1px 1px #000;
}
.ads{
  width: 100%;
  height: 3rem;
  background: #242429;
  
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 2px;
}
.menu{
  width: 100%;
  height: 3rem;
  background: #242429;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
}
.menu span{
  font-size: 15px;
  cursor: pointer;
}

.ads span{
  font-size: 20px;
  margin-left: 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
.create_add{
  width: 100%;
  height: 3rem;
  background: #242429;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 2px;
  padding-right: 20px;
}
.create_add span {
  cursor: pointer;
}
.isbuy{
  color: #757575;
  font-weight: 500;
}
.danger{
  background: red;
}

.ads .create_ad{
float: right;
}
