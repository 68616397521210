.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #242429;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  padding: 24px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.user-info img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}

.trades {
  color: #a3a3a3;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 4px;
}

.details {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.rate,
.usdt-amount,
.limit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;

  margin-right: 16px;
}

.value {
  font-size: 16px;
  font-weight: 400;
}

.cta {
  display: flex;
  justify-content: right;
}

button {
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &.btn-outline-primary{
    background-color: rgb(234, 236, 239);
    color: rgb(30, 35, 41);
    border-radius: 4px;
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

button:hover {
  background-color: #f2b705;
}
.text-center{
  text-align: center!important;
}
.container{
  padding: 0 15px;
}
.flex{
  display: flex;
}
.align-center{
  align-items: center;
}
.space-between{
  justify-content: space-between;
}
.timeline{
  margin-top: 20px;
  margin-bottom: 12px;
  .base-timeline {
    list-style-type: none;
    counter-reset: number; /* number 2021*/
    position: relative;
    display: block;
    z-index: 2;
    width: 85%; /* change or remove*/
    margin: 0 auto;
    padding: 0;
    &:before {
      content: '';
      width: 100%;
      border-top: 4px solid rgb(234, 236, 239);
      display: inline-block;
      position: absolute;
      top: 50%;
      z-index: -1;
    }
    .base-timeline__item {
      position: relative;
      display: inline-block;
      width: calc(100% / 2 - 4px); /* change width */
      &:before {
        display: flex;
        justify-content: center;
        align-items: center;
        counter-increment: number; /* number -1*/
        content: counter(number) ''; 
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background-color: rgb(234, 236, 239);
        color: rgb(112, 122, 138);
        font-weight: bold;
        transition: all 0.6s ease-in-out;
        box-sizing: border-box;
      }
      &.base-timeline__item--active::before {
        background-color: rgb(252, 213, 53);
        border: 2px solid rgb(252, 213, 53);
        color: black;
      }
      &:last-child {
        width: 0;
      }
    }
  }
}

.tabs {
  width: 100%;
  background-color: #fff;
  .tab{
    &:not(:target) {
      display: none;
    }
    &:last-child {
      display: block;
    }
    &:target ~ .tab:last-child {
      display: none;
    }
    ul{
      padding-left: 0;
      margin: 0;
      li{
        display: inline-block;
        padding: 10px;
        width: calc(50% - 20px);
        text-align: center;
        &.active{
          box-shadow: rgba(0, 0, 0, 0.04) 2px -1px 4px inset;
          cursor: pointer;
          height: 20px;
          line-height: 22px;
          color: rgb(33, 40, 51);
          font-weight: 400;
          background-color: rgb(245, 245, 245);
          font-size: 14px;
        }
        a{
          text-decoration: none;
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }
      }
    }
    .tab-inner{
      padding: 15px;
      .select-wrapper{
        border-bottom: 1px dashed rgb(234, 236, 239);
        .input-wrapper{
          margin-bottom: 24px;
          width: 100%;
          label{
            margin-bottom: 10px;
            font-size: 14px;
            color: rgb(30, 35, 41);
          }
          select{
            max-width: 204px;
            width: 100%;
            height: 40px;
          }
          span{
            margin: 0px 20px;
            display: block;
          }
        }
      }
      .price-wrapper{
        padding-top: 16px;
        .price{
          width: 100%;
          span{
            margin-bottom: 10px;
            font-size: 14px;
            color: rgb(30, 35, 41);
            display: block;
            max-width: 170px;
            width: 100%;
            &:nth-last-of-type(1){
              font-size: 24px;
              font-weight: bold;
            }
          }
        }
      }
      .price-type{
        padding-bottom: 8px;
        &:nth-last-of-type(1){
          padding-bottom: 20px;
        }
        .price{
          span{
            display: block;
            max-width: 197px;
            width: 100%;
            padding-bottom: 12px;
            input{
              width: auto;
            }
          }
          .input-group{
            position: relative;
            input{
              max-width: 240px;
              width: 100%;
              background-color: #fff;
              border: 1px solid rgb(230, 232, 234);
              border-radius: 4px;
            }
            .input-group-prepend{
              position: absolute;
              left: 2%;
              right: auto;
              &:nth-last-of-type(1){
                right: 2%;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
}

.set-footer{
  padding-top: 18px;
}
.footer-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px -1px 2px;
    .footer-btn-inner{
      padding: 16px;
      button{
        width: 100%;
      }
    }
}