/* Large screens */
.signup-page {
    background-color: #f5f5f5;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 30rem;
    padding: 3rem;
    margin: 2rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
      
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.8rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  button[type="submit"] {
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #4CAF50;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  button[type="submit"]:hover {
    background-color: #3e8e41;
  }
  
  /* Medium screens */
  @media screen and (max-width: 768px) {
    form {
      width: 80%;
    }
  }
  
  /* Small screens */
  @media screen and (max-width: 576px) {
    h1 {
      font-size: 2rem;
    }
  
    form {
      width: 90%;
      padding: 1rem;
    }
  
    input {
      font-size: 1rem;
      padding: 0.5rem;
    }
  
    button[type="submit"] {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  